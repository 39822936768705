import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/sidebar.css";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";

const Sidebar = () => {
  const { user } = useAuth();
  const [menus, setMenus] = useState([]);
  const [openSubmenus, setOpenSubmenus] = useState({});


  // Função para buscar os dados da API
  useEffect(() => {
    let isMounted = true;
    const fetchPlanData = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/subscription-plan`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        if (isMounted) {
          setMenus(data.menus);
        }
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    };
    fetchPlanData();
    return () => {
      isMounted = false;
    };
  }, [user.school.id]);

  // Função para abrir/fechar submenus
  const toggleMenu = (menuId) => {
    setOpenSubmenus((prevOpenSubmenus) => ({
      ...prevOpenSubmenus,
      [menuId]: !prevOpenSubmenus[menuId],
    }));
  };

  // Função para renderizar os menus dinamicamente
  const renderMenu = (menu) => {
    const hasSubmenu = menu.children && menu.children.length > 0;

    return (
      <li key={menu.id} className={`submenu ${openSubmenus[menu.id] ? "open" : ""}`}>
        <a href={menu.url || "#"} onClick={() => hasSubmenu && toggleMenu(menu.id)}>
          <i className={menu.icon}></i> <span>{menu.name}</span>
          {hasSubmenu && <span className="menu-arrow"></span>}
        </a>
        {hasSubmenu && (
          <ul className={`settings-submenu ${openSubmenus[menu.id] ? "show" : ""}`}>
            {menu.children.map((submenu) => (
              <li key={submenu.id}>
                <NavLink to={submenu.url} activeClassName="active">
                  <i className={submenu.icon}></i> <span>{submenu.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Main Menu</span>
            </li>
            {menus.map((menu) => renderMenu(menu))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
